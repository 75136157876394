var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rra-pra-form"},[_c('ValidationObserver',{directives:[{name:"show",rawName:"v-show",value:(_vm.form === 1),expression:"form === 1"}],ref:"firstForm",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"form-stepper-header"},[_c('div',{staticClass:"form-stepper-header-item",class:{
                active: _vm.form === 1,
              }},[_c('span',{staticClass:"value"},[_vm._v("1")]),_c('span',{staticClass:"label"},[_vm._v("RRA")])]),_c('div',{staticClass:"form-stepper-header-splitter"},[_c('span')]),_c('div',{staticClass:"form-stepper-header-item",class:{
                active: _vm.form === 2,
              }},[_c('span',{staticClass:"value"},[_vm._v("2")]),_c('span',{staticClass:"label"},[_vm._v("PRA")])])])])],1),_c('v-row',[_c('v-col',{staticClass:"form-separator",attrs:{"md":"12"}},[_c('h4',[_vm._v("Data Scooping Visit")])]),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
              label: 'No. Scooping Visit',
              validation: ['required'],
              col_size: 6,
              type: 'select',
              setter: 'scooping_visit_id',
              api: 'GetNewScoopingAll',
              param: {
                rra_no: 0,
              },
              default_label: _vm.formData.scooping_visit_code,
              option: {
                list_pointer: {
                  code: 'id',
                  label: 'label',
                  separator: '-',
                  display: ['data_no', 'desas_name'],
                },
              },
            }},on:{"selected":function($event){return _vm.onSelectScoopingVisit($event)}},model:{value:(_vm.formData.scooping_visit_id),callback:function ($$v) {_vm.$set(_vm.formData, "scooping_visit_id", $$v)},expression:"formData.scooping_visit_id"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
              label: 'Tanggal RRA PRA',
              validation: ['required'],
              col_size: 6,
              type: 'daterange',
              setter: 'date',
            }},model:{value:(_vm.formData.date),callback:function ($$v) {_vm.$set(_vm.formData, "date", $$v)},expression:"formData.date"}})],1),_c('v-col',{staticClass:"form-separator",attrs:{"md":"12"}},[_c('h4',[_vm._v("Batas Wilayah")])]),_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"borderline-wrapper multiple-row-wrapper"},[_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Batas")]),_c('th',[_vm._v("Tipe")]),_c('th',[_vm._v("Provinsi")]),_c('th',[_vm._v("Kabupaten")]),_c('th',[_vm._v("Kecamatan")]),_c('th',[_vm._v("Desa")])])]),_c('tbody',_vm._l((_vm.formData.borderlines),function(borderline,i){return _c('tr',{key:`borderline-${i}`,staticClass:"borderline-item"},[_c('td',{staticClass:"text-center pl-0",attrs:{"variant":"primary"}},[_c('div',{staticClass:"label text-center"},[_vm._v(" "+_vm._s(borderline.label)+" ")])]),_c('td',{staticStyle:{"min-width":"200px"}},[_c('geko-input',{attrs:{"item":{
                        label: 'Type',
                        validation: ['required'],
                        col_size: 6,
                        type: 'select',
                        hide_label: true,
                        option: {
                          default_options: [
                            {
                              text: 'Kabupaten',
                              value: 'Kabupaten',
                            },
                            {
                              text: 'Kecamatan',
                              value: 'Kecamatan',
                            },
                            {
                              text: 'Desa',
                              value: 'Desa',
                            },
                          ],
                          list_pointer: {
                            code: 'value',
                            label: 'text',
                            display: ['text'],
                          },
                        },
                      }},model:{value:(borderline.borderline_type),callback:function ($$v) {_vm.$set(borderline, "borderline_type", $$v)},expression:"borderline.borderline_type"}})],1),_c('td',{staticStyle:{"min-width":"200px"}},[_c('geko-input',{attrs:{"item":{
                        label: 'Provinsi',
                        validation: ['required'],
                        col_size: 6,
                        type: 'select',
                        setter: 'province_id',
                        api: 'GetProvince',
                        default_label: borderline.province_name,
                        hide_label: true,
                        option: {
                          getterKey: 'data.result',
                          list_pointer: {
                            code: 'id',
                            label: 'name',
                            display: ['name'],
                          },
                        },
                      }},on:{"selected":function($event){borderline.province_code = $event.province_code}},model:{value:(borderline.province_id),callback:function ($$v) {_vm.$set(borderline, "province_id", $$v)},expression:"borderline.province_id"}})],1),_c('td',{staticStyle:{"min-width":"200px"}},[_c('geko-input',{attrs:{"item":{
                        label: 'Kabupaten / Kota',
                        validation: ['required'],
                        type: 'select',
                        setter: 'city_id',
                        api: 'GetKabupaten',
                        hide_label: true,

                        param: {
                          province_code: borderline.province_code,
                        },
                        default_label: borderline.city_name,
                        option: {
                          getterKey: 'data.result',
                          list_pointer: {
                            code: 'id',
                            label: 'name',
                            display: ['name'],
                          },
                        },
                      },"disabled":!borderline.province_id},on:{"selected":function($event){borderline.city_code = $event.kabupaten_no}},model:{value:(borderline.city_id),callback:function ($$v) {_vm.$set(borderline, "city_id", $$v)},expression:"borderline.city_id"}})],1),_c('td',{staticStyle:{"min-width":"200px"}},[(
                        ['Kecamatan', 'Desa'].includes(
                          borderline.borderline_type
                        )
                      )?_c('geko-input',{attrs:{"item":{
                        label: 'Kecamatan',
                        validation: ['required'],
                        type: 'select',
                        setter: 'district_id',
                        api: 'GetKecamatan',
                        hide_label: true,
                        param: {
                          kabupaten_no: borderline.city_code,
                        },
                        default_label: borderline.district_name,
                        option: {
                          getterKey: 'data.result',
                          list_pointer: {
                            code: 'id',
                            label: 'name',
                            display: ['name'],
                          },
                        },
                      },"disabled":!borderline.city_id},on:{"selected":function($event){borderline.district_code = $event.kode_kecamatan}},model:{value:(borderline.district_id),callback:function ($$v) {_vm.$set(borderline, "district_id", $$v)},expression:"borderline.district_id"}}):_c('span',{staticClass:"d-block text-center"},[_vm._v("-")])],1),_c('td',{staticStyle:{"min-width":"200px"}},[(borderline.borderline_type == 'Desa')?_c('geko-input',{attrs:{"item":{
                        label: 'Desa',
                        validation: ['required'],
                        col_size: 6,
                        type: 'select',
                        setter: 'village_id',
                        api: 'GetDesa',
                        hide_label: true,
                        param: {
                          kode_kecamatan: borderline.district_code,
                        },
                        default_label: borderline.village_name,
                        option: {
                          getterKey: 'data.result',
                          list_pointer: {
                            code: 'id',
                            label: 'name',
                            display: ['name'],
                          },
                        },
                      },"disabled":!borderline.district_id},on:{"selected":function($event){borderline.village_code = $event.kode_desa}},model:{value:(borderline.village_id),callback:function ($$v) {_vm.$set(borderline, "village_id", $$v)},expression:"borderline.village_id"}}):_c('span',{staticClass:"d-block text-center"},[_vm._v("-")])],1)])}),0)])])]),_c('v-col',{staticClass:"form-separator",attrs:{"md":"12"}},[_c('h4',[_vm._v("Landscape Desa")])]),_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"landscape-village"},[_c('h5',{staticClass:"font-weight-normal"},[_c('span',{staticStyle:{"font-weight":"normal"}},[_vm._v("Luas Wilayah : ")]),(_vm.formData.land_area)?_c('span',[_vm._v(_vm._s(_vm.formData.land_area)+" Ha")]):_vm._e()]),_c('span',{staticClass:"text-grey d-block text-08-em note mt-3 mb-1"},[_vm._v("Kosongkan form apabila tidak ada data")]),_c('v-row',[_vm._l((_vm.landscapeVillageConfig),function(data){return _c('v-col',{key:'data-ld' + data[0],attrs:{"md":"4"}},[_c('geko-input',{attrs:{"item":{
                    label: data[1],
                    validation: [],
                    type: 'number',
                  }},model:{value:(_vm.formData[data[0]]),callback:function ($$v) {_vm.$set(_vm.formData, data[0], $$v)},expression:"formData[data[0]]"}})],1)}),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Jenis Lahan Menurut Masyarakat',
                    validation: [],
                    type: 'textarea',
                  }},model:{value:(_vm.formData.lahan_menurut_masyarakat),callback:function ($$v) {_vm.$set(_vm.formData, "lahan_menurut_masyarakat", $$v)},expression:"formData.lahan_menurut_masyarakat"}})],1)],2)],1)]),_c('v-col',{staticClass:"form-separator d-flex flex-row",staticStyle:{"align-items":"center"},attrs:{"md":"12"}},[_c('h4',[_vm._v("Pola Pemanfaatan Lahan")]),_c('v-btn',{staticClass:"ml-3",attrs:{"small":"","variant":"success"},on:{"click":function($event){return _vm.addRow('landUse', 'rra_no')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)],1),_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"bg-grey"},_vm._l((_vm.landUse),function(item,i){return _c('v-row',{key:'land-use' + i},[_c('v-col',{attrs:{"md":"5"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Pola',
                    validation: ['required'],
                    col_size: 6,
                    type: 'select',
                    option: {
                      default_options: _vm.defaultData.land_use_pattern,
                      list_pointer: {
                        code: 'code',
                        label: 'name',
                        display: ['name'],
                      },
                    },
                  }},model:{value:(item.pattern),callback:function ($$v) {_vm.$set(item, "pattern", $$v)},expression:"item.pattern"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Tanaman',
                    validation: ['required'],
                    col_size: 6,
                    type: 'select',
                    api: 'GetTreesAll',
                    option: {
                      getterKey: 'data.result.data',
                      multiple: true,
                      list_pointer: {
                        code: 'tree_name',
                        label: 'tree_name',
                        display: ['tree_name'],
                      },
                    },
                  }},model:{value:(item.plant),callback:function ($$v) {_vm.$set(item, "plant", $$v)},expression:"item.plant"}})],1),_c('v-col',{attrs:{"md":"1"}},[(_vm.landUse.length > 1)?_c('div',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%","justify-content":"center"}},[_c('button',{staticClass:"text-danger",on:{"click":function($event){return _vm.removeRow('landUse', i)}}},[_c('v-icon',{staticClass:"text-danger"},[_vm._v("mdi-close")])],1)]):_vm._e()])],1)}),1)]),_c('v-col',{staticClass:"form-separator d-flex flex-row",staticStyle:{"align-items":"center"},attrs:{"md":"12"}},[_c('h4',[_vm._v("Tanaman Yang Sudah Ada")]),_c('v-btn',{staticClass:"ml-3",attrs:{"small":"","variant":"success"},on:{"click":function($event){return _vm.addRow('existingPlant', 'rra_no')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)],1),_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"bg-grey"},_vm._l((_vm.existingPlant),function(item,i){return _c('v-row',{key:'epl' + i + i},[_c('v-col',{attrs:{"md":"5"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Kategori',
                    validation: ['required'],
                    type: 'select',
                    option: {
                      default_options: _vm.defaultData.plant_type,
                      list_pointer: {
                        code: 'code',
                        label: 'name',
                        display: ['name'],
                      },
                    },
                  }},model:{value:(item.plant_type),callback:function ($$v) {_vm.$set(item, "plant_type", $$v)},expression:"item.plant_type"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Tanaman',
                    validation: ['required'],
                    type: 'select',
                    api: 'GetTreesAll',
                    option: {
                      getterKey: 'data.result.data',
                      multiple: true,
                      list_pointer: {
                        code: 'tree_name',
                        label: 'tree_name',
                        display: ['tree_name'],
                      },
                    },
                  }},model:{value:(item.plant),callback:function ($$v) {_vm.$set(item, "plant", $$v)},expression:"item.plant"}})],1),_c('v-col',{attrs:{"md":"1"}},[(_vm.existingPlant.length > 1)?_c('div',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%","justify-content":"center"}},[_c('button',{staticClass:"text-danger",on:{"click":function($event){return _vm.removeRow('existingPlant', i)}}},[_c('v-icon',{staticClass:"text-danger"},[_vm._v("mdi-close")])],1)]):_vm._e()])],1)}),1)]),_c('v-col',{staticClass:"form-separator d-flex flex-row",staticStyle:{"align-items":"center"},attrs:{"md":"12"}},[_c('h4',[_vm._v("Kelembagaan Masyarakat")]),_c('v-btn',{staticClass:"ml-3",attrs:{"small":"","variant":"success"},on:{"click":function($event){return _vm.addRow('communityInstitution', 'rra_no')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)],1),(_vm.communityInstitution.length > 0)?_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"bg-grey"},_vm._l((_vm.communityInstitution),function(item,i){return _c('v-row',{key:'ci' + i + i},[_c('v-col',{attrs:{"md":"5"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Nama Lembaga',
                    validation: ['required'],
                    type: 'text',
                  }},model:{value:(item.institution_name),callback:function ($$v) {_vm.$set(item, "institution_name", $$v)},expression:"item.institution_name"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Role',
                    validation: ['required'],
                    type: 'text',
                  }},model:{value:(item.role),callback:function ($$v) {_vm.$set(item, "role", $$v)},expression:"item.role"}})],1),_c('v-col',{attrs:{"md":"1"}},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%","justify-content":"center"}},[_c('button',{staticClass:"text-danger",on:{"click":function($event){return _vm.removeRow('communityInstitution', i)}}},[_c('v-icon',{staticClass:"text-danger"},[_vm._v("mdi-close")])],1)])]),_c('v-col',{attrs:{"md":"11"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Deskripsi',
                    validation: [],
                    type: 'textarea',
                  }},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})],1)],1)}),1)]):_vm._e(),_c('v-col',{staticClass:"form-separator d-flex flex-row",staticStyle:{"align-items":"center"},attrs:{"md":"12"}},[_c('h4',[_vm._v("Potensi Pertanian Organik")]),_c('v-btn',{staticClass:"ml-3",attrs:{"small":"","variant":"success"},on:{"click":function($event){return _vm.addRow('organicPotentials', 'rra_no')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)],1),(_vm.organicPotentials.length > 0)?_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"bg-grey"},_vm._l((_vm.organicPotentials),function(item,i){return _c('v-row',{key:'op' + i + i},[_c('v-col',{attrs:{"md":"5"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Nama Petani',
                    validation: ['required'],
                    type: 'text',
                  }},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Kategori Potensi',
                    validation: ['required'],
                    type: 'text',
                  }},model:{value:(item.potential_category),callback:function ($$v) {_vm.$set(item, "potential_category", $$v)},expression:"item.potential_category"}})],1),_c('v-col',{attrs:{"md":"1"}},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%","justify-content":"center"}},[_c('button',{staticClass:"text-danger",on:{"click":function($event){return _vm.removeRow('organicPotentials', i)}}},[_c('v-icon',{staticClass:"text-danger"},[_vm._v("mdi-close")])],1)])]),_c('v-col',{attrs:{"md":"11"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Sumber Pertanian Organik',
                    validation: ['required'],
                    type: 'text',
                  }},model:{value:(item.source),callback:function ($$v) {_vm.$set(item, "source", $$v)},expression:"item.source"}})],1),_c('v-col',{attrs:{"md":"11"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Deskripsi',
                    validation: [],
                    type: 'textarea',
                  }},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})],1)],1)}),1)]):_vm._e(),_c('v-col',{staticClass:"form-separator d-flex flex-row",staticStyle:{"align-items":"center"},attrs:{"md":"12"}},[_c('h4',[_vm._v("Pemasaran Hasil Produksi")]),_c('v-btn',{staticClass:"ml-3",attrs:{"small":"","variant":"success"},on:{"click":function($event){return _vm.addRow('productionMarketing', 'rra_no')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)],1),_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"bg-grey"},_vm._l((_vm.productionMarketing),function(item,i){return _c('v-row',{key:'pm' + i + i},[_c('v-col',{attrs:{"md":"5"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Nama Komoditas',
                    validation: ['required'],
                    type: 'text',
                  }},model:{value:(item.commodity_name),callback:function ($$v) {_vm.$set(item, "commodity_name", $$v)},expression:"item.commodity_name"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Kapasitas Komoditas (kg)',
                    validation: [],
                    type: 'text',
                  }},model:{value:(item.capacity),callback:function ($$v) {_vm.$set(item, "capacity", $$v)},expression:"item.capacity"}})],1),_c('v-col',{attrs:{"md":"1"}},[(_vm.productionMarketing.length > 1)?_c('div',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%","justify-content":"center"}},[_c('button',{staticClass:"text-danger",on:{"click":function($event){return _vm.removeRow('productionMarketing', i)}}},[_c('v-icon',{staticClass:"text-danger"},[_vm._v("mdi-close")])],1)]):_vm._e()]),_c('v-col',{attrs:{"md":"5"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Metode',
                    validation: ['required'],
                    type: 'select',
                    option: {
                      default_options:
                        _vm.defaultData.production_marketing_method,
                      list_pointer: {
                        code: 'code',
                        label: 'name',
                        display: ['name'],
                      },
                    },
                  }},model:{value:(item.method),callback:function ($$v) {_vm.$set(item, "method", $$v)},expression:"item.method"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Periode Pemasaran Komoditas',
                    validation: ['required'],
                    type: 'select',
                    option: {
                      default_options:
                        _vm.defaultData.production_marketing_period,
                      list_pointer: {
                        code: 'code',
                        label: 'name',
                        display: ['name'],
                      },
                    },
                  }},model:{value:(item.method),callback:function ($$v) {_vm.$set(item, "method", $$v)},expression:"item.method"}})],1),_c('v-col',{attrs:{"md":"11"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Deskripsi',
                    validation: ['required'],
                    type: 'textarea',
                  }},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})],1)],1)}),1)]),_c('v-col',{staticClass:"form-separator d-flex flex-row",staticStyle:{"align-items":"center"},attrs:{"md":"12"}},[_c('h4',[_vm._v("Identifikasi Petani Invoatif")]),_c('v-btn',{staticClass:"ml-3",attrs:{"small":"","variant":"success"},on:{"click":function($event){return _vm.addRow('innovativeFarmer', 'rra_no')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)],1),(_vm.innovativeFarmer.length > 0)?_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"bg-grey"},_vm._l((_vm.innovativeFarmer),function(item,i){return _c('v-expansion-panels',{key:'pm' + i + i,attrs:{"focusable":""}},[_c('v-expansion-panel',{staticClass:"mb-3"},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(item.farmer_name || "Masukkan Data Petani Baru")+" ")]),_c('v-expansion-panel-content',{staticClass:"pt-4"},[_c('v-row',[_c('v-col',{attrs:{"md":"5"}},[_c('geko-input',{attrs:{"item":{
                          label: 'Nama Petani',
                          validation: ['required'],
                          type: 'text',
                        }},model:{value:(item.farmer_name),callback:function ($$v) {_vm.$set(item, "farmer_name", $$v)},expression:"item.farmer_name"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
                          label: 'Spesialisasi Petani',
                          validation: ['required'],
                          type: 'text',
                        }},model:{value:(item.specialitation),callback:function ($$v) {_vm.$set(item, "specialitation", $$v)},expression:"item.specialitation"}})],1),_c('v-col',{attrs:{"md":"11"}},[_c('geko-input',{attrs:{"item":{
                          label: 'Potensial',
                          validation: ['required'],
                          type: 'text',
                        }},model:{value:(item.potential),callback:function ($$v) {_vm.$set(item, "potential", $$v)},expression:"item.potential"}})],1),_c('v-col',{attrs:{"md":"11"}},[_c('geko-input',{attrs:{"item":{
                          label: 'Deskripsi',
                          validation: ['required'],
                          type: 'textarea',
                        }},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})],1),_c('v-col',{attrs:{"md":"1"}},[(_vm.innovativeFarmer.length > 0)?_c('div',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%","justify-content":"center"}},[_c('button',{staticClass:"text-danger",on:{"click":function($event){return _vm.removeRow('innovativeFarmer', i)}}},[_c('v-icon',{staticClass:"text-danger"},[_vm._v("mdi-close")])],1)]):_vm._e()])],1)],1)],1)],1)}),1)]):_vm._e(),_c('v-col',{staticClass:"form-separator d-flex flex-row",staticStyle:{"align-items":"center"},attrs:{"md":"12"}},[_c('h4',[_vm._v("Data Dusun")]),_c('v-btn',{staticClass:"ml-3",attrs:{"small":"","variant":"success"},on:{"click":function($event){return _vm.addRow('dusuns', 'potential', 0)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)],1),_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"bg-grey"},_vm._l((_vm.dusuns),function(item,i){return _c('v-expansion-panels',{key:'acc' + i + i,attrs:{"focusable":""}},[_c('v-expansion-panel',{staticClass:"mb-3"},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(item.dusun_name || "Masukkan Data Dusun")+" ")]),_c('v-expansion-panel-content',{staticClass:"pt-4 pb-5"},[_c('v-row',{staticClass:"pb-5"},[_c('v-col',{attrs:{"md":"5"}},[_c('geko-input',{attrs:{"item":{
                          label: 'Nama Dusun',
                          validation: ['required'],
                          type: 'text',
                        }},model:{value:(item.dusun_name),callback:function ($$v) {_vm.$set(item, "dusun_name", $$v)},expression:"item.dusun_name"}})],1),_c('v-col',{attrs:{"md":"6"}},[(item.potential == 0)?_c('geko-input',{attrs:{"item":{
                          label: 'Jumlah KK',
                          validation: ['required'],
                          type: 'number',
                        }},model:{value:(item.total_kk),callback:function ($$v) {_vm.$set(item, "total_kk", $$v)},expression:"item.total_kk"}}):_vm._e()],1),_c('v-col',{attrs:{"md":"1"}},[(_vm.dusuns.length > 1)?_c('div',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%","justify-content":"center"}},[_c('button',{staticClass:"text-danger",on:{"click":function($event){return _vm.removeRow('dusuns', i)}}},[_c('v-icon',{staticClass:"text-danger"},[_vm._v("mdi-close")])],1)]):_vm._e()]),_c('v-col',{attrs:{"md":"12"}},[_c('geko-input',{attrs:{"item":{
                          view_data: 'potential',
                          type: 'select-radio',
                          label: 'Apakah dusun ini berpotensi?',
                          validation: [],
                          option: {
                            list_pointer: {
                              label: 'label',
                              code: 'code',
                              display: ['label'],
                            },
                            default_options: [
                              {
                                label: 'Berpotensi',
                                code: '1',
                              },
                              {
                                label: 'Tidak Berpotensi',
                                code: '0',
                              },
                            ],
                          },
                        }},model:{value:(item.potential),callback:function ($$v) {_vm.$set(item, "potential", $$v)},expression:"item.potential"}})],1),(item.potential == 1)?_c('v-row',{staticClass:"px-2"},[_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                            view_data: 'accessibility',
                            type: 'select',
                            label: 'Aksesibilitas',
                            validation: ['required'],
                            option: {
                              default_options: _vm.defaultData.accessibility,
                              list_pointer: {
                                label: 'name',
                                code: 'code',
                                display: ['name'],
                              },
                            },
                          }},model:{value:(item.accessibility),callback:function ($$v) {_vm.$set(item, "accessibility", $$v)},expression:"item.accessibility"}})],1),_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                            label: 'Foto Akses Jalan',
                            validation: ['required'],
                            type: 'upload',
                            api: 'rra_pra/upload.php',
                            directory: 'photos',
                            upload_type: 'image/*',
                            setter: 'dusun_access_photo',
                            view_data: 'dusun_access_photo',
                            option: {
                              label_hint:
                                'Klik gambar untuk memilih berkas yang akan diunggah',
                              max_size: 5,
                              max: 5,
                            },
                          }},model:{value:(item.dusun_access_photo),callback:function ($$v) {_vm.$set(item, "dusun_access_photo", $$v)},expression:"item.dusun_access_photo"}})],1),_c('v-col',{staticClass:"form-separator",attrs:{"md":"12"}},[_c('h4',[_vm._v("Luas Dusun & Lahan Kering")])]),_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                            label: 'Tipe Sumber Data Luas Dusun',
                            validation: ['required'],
                            type: 'select',
                            view_data: item.data_land_area_source,
                            option: {
                              default_options: _vm.defaultData.data_type_source,

                              list_pointer: {
                                label: 'name',
                                code: 'code',
                                display: ['name'],
                              },
                            },
                          }},model:{value:(item.data_land_area_source),callback:function ($$v) {_vm.$set(item, "data_land_area_source", $$v)},expression:"item.data_land_area_source"}})],1),_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                            label: 'Luas Dusun',
                            validation: ['required'],
                            type: 'number',
                          }},model:{value:(item.land_area),callback:function ($$v) {_vm.$set(item, "land_area", $$v)},expression:"item.land_area"}})],1),_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                            label: 'Luas Lahan Kering',
                            validation: ['required'],
                            type: 'number',
                          }},model:{value:(item.dry_land_area),callback:function ($$v) {_vm.$set(item, "dry_land_area", $$v)},expression:"item.dry_land_area"}})],1),_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                            label: 'Tipe Sumber Data (Luas Lahan Kering)',
                            validation: ['required'],
                            type: 'select',
                            view_data: item.data_land_area_source,
                            option: {
                              default_options: _vm.defaultData.data_type_source,

                              list_pointer: {
                                label: 'name',
                                code: 'code',
                                display: ['name'],
                              },
                            },
                          }},model:{value:(item.dry_land_area_source),callback:function ($$v) {_vm.$set(item, "dry_land_area_source", $$v)},expression:"item.dry_land_area_source"}})],1),_c('v-col',{staticClass:"form-separator",attrs:{"md":"12"}},[_c('h4',[_vm._v("PIC Dusun")])]),_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                            label: 'PIC Dusun',
                            validation: ['required'],
                            type: 'text',
                          }},model:{value:(item.pic_dusun),callback:function ($$v) {_vm.$set(item, "pic_dusun", $$v)},expression:"item.pic_dusun"}})],1),_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                            label: 'Jabatan',
                            validation: ['required'],
                            type: 'text',
                          }},model:{value:(item.position),callback:function ($$v) {_vm.$set(item, "position", $$v)},expression:"item.position"}})],1),_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                            label: 'No HP',
                            validation: ['required'],
                            type: 'text',
                          }},model:{value:(item.phone),callback:function ($$v) {_vm.$set(item, "phone", $$v)},expression:"item.phone"}})],1),_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                            label: 'Whatsapp',
                            validation: [],
                            type: 'text',
                          }},model:{value:(item.whatsapp),callback:function ($$v) {_vm.$set(item, "whatsapp", $$v)},expression:"item.whatsapp"}})],1),_c('v-col',{staticClass:"form-separator",attrs:{"md":"12"}},[_c('h4',[_vm._v("Data Populasi")])]),_c('v-col',{attrs:{"lg":"2"}},[_c('geko-input',{attrs:{"item":{
                            label: 'RT',
                            validation: ['required'],
                            type: 'number',
                          }},model:{value:(item.total_rt),callback:function ($$v) {_vm.$set(item, "total_rt", $$v)},expression:"item.total_rt"}})],1),_c('v-col',{attrs:{"lg":"2"}},[_c('geko-input',{attrs:{"item":{
                            label: 'RW',
                            validation: ['required'],
                            type: 'number',
                          }},model:{value:(item.total_rw),callback:function ($$v) {_vm.$set(item, "total_rw", $$v)},expression:"item.total_rw"}})],1),_c('v-col',{attrs:{"lg":"4"}},[_c('geko-input',{attrs:{"item":{
                            label: 'Jumlah Laki-Laki',
                            validation: [],
                            type: 'number',
                          }},model:{value:(item.total_male),callback:function ($$v) {_vm.$set(item, "total_male", $$v)},expression:"item.total_male"}})],1),_c('v-col',{attrs:{"lg":"4"}},[_c('geko-input',{attrs:{"item":{
                            label: 'Jumlah Perempuan',
                            validation: [],
                            type: 'number',
                          }},model:{value:(item.total_female),callback:function ($$v) {_vm.$set(item, "total_female", $$v)},expression:"item.total_female"}})],1),_c('v-col',{attrs:{"lg":"4"}},[_c('geko-input',{attrs:{"item":{
                            label: 'Jumlah KK',
                            validation: ['required'],
                            type: 'number',
                          }},model:{value:(item.total_kk),callback:function ($$v) {_vm.$set(item, "total_kk", $$v)},expression:"item.total_kk"}})],1),_c('v-col',{attrs:{"lg":"4"}},[_c('geko-input',{attrs:{"item":{
                            label: 'Jumlah Keluarga Petani (KK)',
                            validation: [],
                            type: 'number',
                          }},model:{value:(item.total_farmer_family),callback:function ($$v) {_vm.$set(item, "total_farmer_family", $$v)},expression:"item.total_farmer_family"}})],1),_c('v-col',{attrs:{"lg":"4"}},[_c('geko-input',{attrs:{"item":{
                            label: 'Jumlah Keluarga Non Petani (KK)',
                            validation: [],
                            type: 'number',
                          }},model:{value:(item.total_non_farmer_family),callback:function ($$v) {_vm.$set(item, "total_non_farmer_family", $$v)},expression:"item.total_non_farmer_family"}})],1),_c('v-col',{attrs:{"lg":"4"}},[_c('geko-input',{attrs:{"item":{
                            label: 'Rata-Rata Anggota Keluarga',
                            validation: [],
                            type: 'number',
                          }},model:{value:(item.average_family_member),callback:function ($$v) {_vm.$set(item, "average_family_member", $$v)},expression:"item.average_family_member"}})],1),_c('v-col',{attrs:{"lg":"4"}},[_c('geko-input',{attrs:{"item":{
                            label: 'Rata-Rata Anggota Keluarga Petani',
                            validation: [],
                            type: 'number',
                          }},model:{value:(item.average_farmer_family_member),callback:function ($$v) {_vm.$set(item, "average_farmer_family_member", $$v)},expression:"item.average_farmer_family_member"}})],1),_c('v-col',{attrs:{"lg":"4"}},[_c('geko-input',{attrs:{"item":{
                            label: 'Rata-Rata Anggota Keluarga Non Petani',
                            validation: [],
                            type: 'number',
                          }},model:{value:(item.average_non_farmer_family_member),callback:function ($$v) {_vm.$set(item, "average_non_farmer_family_member", $$v)},expression:"item.average_non_farmer_family_member"}})],1),_c('v-col',{staticClass:"form-separator",attrs:{"md":"12"}},[_c('h4',[_vm._v("Data Edukasi")])]),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
                            label: 'SD - SMP',
                            validation: ['required'],
                            type: 'number',
                          }},model:{value:(item.education_elementary_junior_hs),callback:function ($$v) {_vm.$set(item, "education_elementary_junior_hs", $$v)},expression:"item.education_elementary_junior_hs"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
                            label: 'SMA',
                            validation: ['required'],
                            type: 'number',
                          }},model:{value:(item.education_senior_hs),callback:function ($$v) {_vm.$set(item, "education_senior_hs", $$v)},expression:"item.education_senior_hs"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
                            label: 'Kuliah',
                            validation: [],
                            type: 'number',
                          }},model:{value:(item.education_college),callback:function ($$v) {_vm.$set(item, "education_college", $$v)},expression:"item.education_college"}})],1),_c('v-col',{staticClass:"form-separator",attrs:{"md":"12"}},[_c('h4',[_vm._v("Data Produktifitas")])]),_c('v-col',{attrs:{"md":"4"}},[_c('geko-input',{attrs:{"item":{
                            label: 'Tipe Sumber Data',
                            validation: ['required'],
                            type: 'select',
                            view_data: item.data_productive_source,
                            option: {
                              default_options: _vm.defaultData.data_type_source,

                              list_pointer: {
                                label: 'name',
                                code: 'code',
                                display: ['name'],
                              },
                            },
                          }},model:{value:(item.data_productive_source),callback:function ($$v) {_vm.$set(item, "data_productive_source", $$v)},expression:"item.data_productive_source"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('geko-input',{attrs:{"item":{
                            label: 'Produktif',
                            validation: ['required'],
                            type: 'number',
                          }},model:{value:(item.age_productive),callback:function ($$v) {_vm.$set(item, "age_productive", $$v)},expression:"item.age_productive"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('geko-input',{attrs:{"item":{
                            label: 'Non-Produktif',
                            validation: [],
                            type: 'number',
                          }},model:{value:(item.age_non_productive),callback:function ($$v) {_vm.$set(item, "age_non_productive", $$v)},expression:"item.age_non_productive"}})],1),_c('v-col',{staticClass:"form-separator",attrs:{"md":"12"}},[_c('h4',[_vm._v("Mata Pencaharian Masyarakat")])]),_vm._l((_vm.dusunJobs),function(job,j){return _c('v-col',{key:'dusuns-' + i + j,attrs:{"md":"6"}},[_c('v-switch',{attrs:{"label":'Ada ' + job[0],"color":"success"},model:{value:(item[job[1]]),callback:function ($$v) {_vm.$set(item, job[1], $$v)},expression:"item[job[1]]"}})],1)})],2):_vm._e()],1)],1)],1)],1)}),1)]),_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"d-flex flex-row w-100",staticStyle:{"justify-content":"flex-end"}},[_c('v-btn',{staticClass:"mr-2 outline",attrs:{"variant":"success","type":"submit"},on:{"click":function($event){return _vm.onSubmit(true)}}},[_vm._v(" Tambahkan Data RRA & Isi Data PRA ")]),_c('v-btn',{attrs:{"type":"submit","variant":"success"},on:{"click":function($event){return _vm.onSubmit(false)}}},[_vm._v(" Tambahkan Data RRA ")])],1)])],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }