var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{attrs:{"id":"Trees"}},[(_vm.$route.name !== 'Login')?_c('v-navigation-drawer',{class:`custom-sidebar ${
      _vm.$store.state.theme == 'dark' ? 'dark-bg-gradient' : 'light-bg-gradient'
    } rounded-xl ml-2 mt-2 elevation-5`,staticStyle:{"height":"auto","max-height":"97.5vh"},attrs:{"width":'auto',"dark":_vm.$store.state.theme == 'dark',"app":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('div',{staticClass:"sidebar-header"},[_c('img',{attrs:{"src":"/images/GEKO_short.png"}}),_c('div',{staticClass:"d-flex flex-column sidebar-profile w-100"},[_c('div',{staticClass:"d-flex flex-row px-2 mb-2 w-100",staticStyle:{"align-items":"center","width":"100%"}},[_c('div',{staticClass:"avatar"},[_vm._v(" "+_vm._s(_vm.avatarHelper(_vm.$store.state.User.name))+" ")]),_c('div',{staticClass:"d-flex flex-column sidebar-profile-name"},[_c('h6',[_vm._v(_vm._s(_vm.$store.state.User ? _vm.$store.state.User.name : ""))]),_c('p',{staticClass:"mb-0 text-08-em"},[_vm._v(" "+_vm._s(_vm.$store.state.User ? _vm.$store.state.User.role_name : "")+" ")])])]),_c('div',{staticClass:"sidebar-header-menu",class:{
              open: _vm.profileOpen,
            }},[_c('button',{on:{"click":function($event){return _vm.$router.push('/GantiPassword')}}},[_c('v-icon',[_vm._v("mdi-lock-outline")]),_c('span',[_vm._v("Ganti Password")])],1),_c('button',{staticClass:"danger",on:{"click":_vm.logout}},[_c('v-icon',[_vm._v("mdi-logout")]),_c('span',[_vm._v("Logout")])],1)]),_c('button',{staticClass:"sidebar-profile-button",on:{"click":function($event){_vm.profileOpen = !_vm.profileOpen}}},[(!_vm.profileOpen)?_c('v-icon',[_vm._v("mdi-chevron-down")]):_c('v-icon',[_vm._v("mdi-chevron-up")])],1)])])]},proxy:true}],null,false,1442343016),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"sidebar-menus"},_vm._l((_vm.items),function(group,i){return (
          group.title !== 'Activities' ||
          (group.title == 'Activities' && _vm.isCarbon !== null)
        )?_c('div',{key:`group-${i}`,staticClass:"sidebar-menu-item",class:{
          activities: group.title == 'Activities',
          carbon: _vm.isCarbon && group.title == 'Activities',
          'non-carbon': !_vm.isCarbon && group.title == 'Activities',
        }},[(!Array.isArray(group.items))?_c('button',{staticClass:"sidebar-button",class:{
            active: _vm.$route.path == group.to,
          },on:{"click":function($event){return _vm.$router.push({
              path: group.to,
              query: {
                view: 'list',
              },
            })}}},[_c('v-icon',[_vm._v(_vm._s(group.icon))]),_c('span',[_vm._v(_vm._s(group.title))])],1):(group.title !== 'Activities')?_c('p',{staticClass:"sidebar-title mb-0"},[_vm._v(" "+_vm._s(group.title)+" ")]):(group.title === 'Activities')?_c('div',{staticClass:"sidebar-title mb-0 activities d-flex flex-row justify-content-between align-items-center",class:{
            carbon: _vm.isCarbon && group.title == 'Activities',
            'non-carbon': !_vm.isCarbon && group.title == 'Activities',
          }},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(group.title))]),_c('div',{staticClass:"carbon-switch"},[_c('button',{class:{
                active: _vm.isCarbon,
              },on:{"click":function($event){_vm.isCarbon = true}}},[_vm._v(" Carbon ")]),_c('button',{class:{
                active: !_vm.isCarbon,
              },on:{"click":function($event){_vm.isCarbon = false}}},[_vm._v(" Non Carbon ")])])]):_vm._e(),_vm._l((group.title == 'Activities'
            ? group.items.filter((x) =>
                x.type.includes(_vm.isCarbon ? 'carbon' : 'non-carbon')
              )
            : group.items),function(menu,j){return (Array.isArray(group.items))?_c('a',{key:`menu-side-${j}-${i}`,staticClass:"sidebar-button",class:{
            active: _vm.$route.path == menu.to,
          },attrs:{"href":`#${menu.to}?view=list`}},[_c('v-icon',[_vm._v(_vm._s(_vm.$route.path == menu.to ? menu.icon : `${menu.icon}-outline`))]),_c('span',[_vm._v(_vm._s(menu.title)+" "),(menu.update)?_c('span',{staticClass:"badge bg-info text-info"},[_vm._v("v2")]):_vm._e()])],1):_vm._e()})],2):_vm._e()}),0),_c('h5',{staticClass:"text-center pb-1",staticStyle:{"font-weight":"300","font-size":"70%","color":"gray"}},[_vm._v(" GEKO v"+_vm._s(_vm.$_config.version)+" ")]),_c('h5',{staticClass:"text-center pb-2",staticStyle:{"font-weight":"550","font-size":"80%","color":"black"}},[_vm._v(" Trees4Trees © 2024 ")])]):_vm._e(),(_vm.$route.name !== 'Login')?_c('button',{staticClass:"toggle-button-mobile"},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}})],1):_vm._e(),(_vm.isLoggedIn)?_c('v-app-bar',{class:`${
      _vm.$store.state.theme == 'dark' ? 'dark-bg-gradient' : 'light-bg-gradient'
    } mx-2 ml-2 ml-lg-5 mt-2 rounded-pill`,attrs:{"dark":_vm.$store.state.theme == 'dark',"app":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('v-toolbar-title',{staticClass:"pl-1 fontall"},[_c('a',{class:'green--text--theme',staticStyle:{"text-decoration":"none"},attrs:{"href":"/"}},[_vm._v(" Trees4Trees ")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"gray darken-1"},on:{"click":_vm.toggleFullScreen}},'v-btn',attrs,false),on),[(_vm.isFullScreen)?_c('v-icon',[_vm._v("mdi-arrow-collapse-all")]):_c('v-icon',[_vm._v("mdi-arrow-expand-all")])],1)]}}],null,false,3131440814)},[_vm._v(" "+_vm._s(_vm.isFullScreen ? "Close" : "Go")+" FullScreen "+_vm._s(_vm.isFullScreen ? "(esc)" : "")+" ")]),_c('v-menu',{attrs:{"offset-y":"","rounded":"xl"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"px-1 mr-1",attrs:{"text":"","size":"200","rounded":""}},on),[_c('div',{staticClass:"mr-2",staticStyle:{"text-align":"right"}},[_c('p',{staticClass:"font-weight-bolder mb-0",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.nameadmin)+" ")]),_c('span',{staticClass:"d-none d-md-block",staticStyle:{"font-size":"9px"}},[_vm._v(_vm._s(_vm.statusadmin))])]),_c('v-avatar',{attrs:{"size":"35"}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-account-circle")])],1)],1)]}}],null,false,822797311)},[_c('v-list',{staticStyle:{"overflow":"hidden !important"},attrs:{"rounded":""}},_vm._l((_vm.itemsprofile),function(item,index){return _c('v-list-item',{key:index,attrs:{"color":"primary"},on:{"click":function($event){return _vm.selectSection(item)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":"","color":"black"}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.title))],1)],1)}),1)],1)],1):_vm._e(),(_vm.isLoggedIn)?_c('v-app-bar',{class:`${
      _vm.$store.state.theme == 'dark' ? 'dark-bg-gradient' : 'light-bg-gradient'
    } mx-5 mr-3 mb-2 rounded-xl`,attrs:{"app":"","dense":"","bottom":"","absolute":"","dark":_vm.$store.state.theme == 'dark'}},[_c('v-col',{class:`text-center green--text ${
        _vm.$store.state.theme == 'dark' ? 'text--lighten-4' : 'text--darken-1'
      }`,attrs:{"cols":"12"}},[_vm._v(" GEKOWeb v"+_vm._s(_vm.$store.state.packageVersion)+" © Trees4Trees ")])],1):_vm._e(),_c('v-main',{class:`gradient-animate ${_vm.$store.state.theme} ${
      _vm.isLoggedIn ? 'logged-in' : ''
    }`},[_c('router-view',{staticClass:"pb-4 pl-0 pl-lg-3",staticStyle:{"z-index":"1","position":"relative"}})],1),_c('v-overlay',{attrs:{"value":_vm.$store.state.loadingOverlay}},[_c('div',{staticClass:"d-flex flex-column align-center justify-center"},[_c('LottieAnimation',{ref:"anim",staticStyle:{"height":"64px"},attrs:{"animationData":_vm.lottie.data.loading,"loop":true}}),(_vm.$store.state.loadingOverlayText)?_c('p',{staticClass:"mt-2 mb-0",attrs:{"data-aos":"zoom-in","data-aos-delay":"200","data-aos-duration":"800"},domProps:{"innerHTML":_vm._s(_vm.$store.state.loadingOverlayText)}}):_vm._e()],1)]),_c('v-overlay',{attrs:{"value":_vm.$store.state.maintenanceOverlay}},[_c('div',{staticClass:"d-flex flex-column align-center position-relative"},[_c('LottieAnimation',{ref:"anim2",staticStyle:{"max-width":"100%"},attrs:{"animationData":_vm.lottie.data.maintenance,"loop":true}}),_c('v-btn',{attrs:{"rounded":""},on:{"click":function($event){return _vm.$router.push('/')}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-view-dashboard")]),_vm._v("Back to Dashboard")],1)],1)]),_c('vue-easy-lightbox',{attrs:{"visible":_vm.$store.state.lightbox.show,"imgs":_vm.$store.state.lightbox.imgs,"index":_vm.$store.state.lightbox.index},on:{"hide":() => {
        _vm.$store.state.lightbox.show = false;
      }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }